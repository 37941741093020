import React from "react";
import Videos from "./Videos";
export default function VideosPage() {
  return (
    <React.Fragment>
      <section className="spacing" />
      <Videos />
    </React.Fragment>
  );
}
