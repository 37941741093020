import React from "react";
import "./Header.scss";
import { Link } from "react-router-dom";
import { Row, Col, BackTop } from "antd";
import { ArrowUp2 } from "iconsax-react";
import "./Footer.scss";

export default function Footer() {
  return (
    <React.Fragment>
      <section className="footer bg-seat-pattern">
        <div className="container">
          <Row gutter={[12, 22]} className="social-media text-center mt-4 pb-3">
            <h5 className="d-block w-100 text-capitalize text-white mb-0">
              Follow us on social media
            </h5>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
            >
              <div className="social-media-links">
                <a
                  href="https://www.facebook.com/naadu.2023.indian.film"
                  target="_blank"
                  rel="noopener"
                >
                  <i class="fa fa-facebook" aria-hidden="true" />
                </a>
                <a
                  href="https://www.instagram.com/naadu_2023_indian_film/"
                  target="_blank"
                  rel="noopener"
                >
                  <i class="fa fa-instagram" aria-hidden="true" />
                </a>
                <a
                  href="https://youtube.com/@sriarchmedia"
                  target="_blank"
                  rel="noopener"
                >
                  <i class="fa fa-youtube-play" aria-hidden="true" />
                </a>
                <a
                  href="https://twitter.com/Naadu_2023_film"
                  target="_blank"
                  rel="noopener"
                >
                  <i class="fa fa-twitter" aria-hidden="true" />
                </a>
                <a
                  href="https://www.linkedin.com/showcase/naadu/"
                  target="_blank"
                  rel="noopener"
                >
                  <i class="fa fa-linkedin" aria-hidden="true" />
                </a>
                <a
                  href="https://en.wikipedia.org/wiki/Draft:Naadu_(2023_Indian_film)"
                  target="_blank"
                  rel="noopener"
                >
                  <i class="fa fa-wikipedia-w" aria-hidden="true" />
                </a>
                <a
                  href="https://www.imdb.com/title/tt28182832/"
                  target="_blank"
                  rel="noopener"
                >
                  <i class="fa fa-imdb" aria-hidden="true" />
                </a>
              </div>
              <p className="text-light-white pt-4">
                © {new Date().getFullYear()} Copyright Naadu - ( 2023 Indian
                Film ) | Sri Arch Media and Entertainment PVT LTD. All Right
                Reserved.
              </p>
            </Col>
          </Row>
        </div>
      </section>
      <BackTop>
        <div className="back-to-top">
          <ArrowUp2 />
        </div>
      </BackTop>
    </React.Fragment>
  );
}
