import React from "react";
import { Row, Col } from "antd";

// thumbnails

import tumbnail1 from "../../assets/images/news/naadu/naadu-frstlook-thumbnail.jpg";
import tumbnail2 from "../../assets/images/news/naadu/firstlook-naadu-image.jpg";
import tumbnail3 from "../../assets/images/news/naadu/naadu-reallife-story.jpg";
import tumbnail4 from "../../assets/images/news/naadu/naadu-reallife-story-dir-saravanan.jpg";

const newsDetails = [
  {
    id: 1,
    title: "The first look of  Tharsan-M Saravanan's Naadu out",
    description:
      "We reported recently that the shoot of Naadu, starring Bigg Boss-fame Tharsan and directed by Engeyum Eppodhum-fame M Saravanan was wrapped up. The film stars Mahima Nambiar as the female lead. ",
    thumbnail: [tumbnail1],
    date: "11th November 2022",
    source: "cinemaexpress",
    url: "https://www.cinemaexpress.com/tamil/news/2022/nov/11/the-first-look-of-tharsan-m-saravanans-naadu-out-36536.html",
  },
  {
    id: 2,
    title:
      "The first look of 'Nadu' directed by Saravanan, the director of 'Engeyum Vaami', has been released.",
    description:
      "Engeyum Alami starring Jai, Sarvanand, Anjali and others was directed by M. Saravanan. The film was released and received a huge response from the fans. Following this, Saravanan directed the action film Ivan Athye Makal, starring Vikram Prabhu. The film was also a box office success.",
    thumbnail: [tumbnail2],
    date: "12 NOVEMBER , 2022",
    source: "NEWS18 TAMIL",
    url: "https://tamil.news18.com/news/entertainment/cinema-bigg-boss-tharshan-new-movie-naadu-first-look-835434.html",
  },
  {
    id: 3,
    title:
      "'Naadu' based on real-life incident in Kolli hills, says director Saravanan",
    description:
      "Director M. Saravanan, who has delivered critically acclaimed superhit films such as ‘Engeyum Eppothum’ and ‘Ivan Vera Mathiri’, said that the spark for his next film, ‘Naadu’ came from a real life incident which he witnessed in Kolli hills.",
    thumbnail: [tumbnail3],
    date: "13 November 2022",
    source: "imdb",
    url: "https://www.imdb.com/news/ni63833362",
  },
  {
    id: 4,
    title: "Naadu based on real-life incident in Kolli hills: Dir Saravanan",
    description:
      "The director, who is known for making socially responsible films, said that the spark for this film came, “Several years ago. I had gone to the Kolli hills. An incident I witnessed there had a profound impact on me for a long time. I decided to make it into a script and that is how Naadu happened.”",
    thumbnail: [tumbnail4],
    date: "14 NOVEMBER, 2022",
    source: "Dt Next Bureau",
    url: "https://www.dtnext.in/cinema/2022/11/13/naadu-based-on-real-life-incident-in-kolli-hills-dir-saravanan",
  },
];

export default class News extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section className="spacing" />
        <section className="news-list spacing">
          <div className="container">
            <h1 className="d-block text-center w-100 text-capitalize text-white mt-4 mb-4">
              News
            </h1>
            <Row gutter={[30, 20]} align="middle" className="pb-3 ">
              {newsDetails.map((newsList, index) => (
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 8 }}
                  lg={{ span: 8 }}
                  data-index={index}
                >
                  <a href={`${newsList.url}`} target="_blank" rel="noreferrer">
                    <div className="card">
                      <div className="image">
                        <img
                          src={newsList.thumbnail}
                          alt={`${newsList.title}`}
                          title={`${newsList.title}`}
                        />
                        <div className="date-source">
                          <div className="date">{newsList.date}</div>
                          <div className="source">{newsList.source}</div>
                        </div>
                      </div>
                      <h3>{newsList.title}</h3>
                      <p>{newsList.description}</p>
                    </div>
                  </a>
                </Col>
              ))}
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
