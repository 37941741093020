import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "../Screens/LandingPage/Home";
import AboutUs from "../Screens/AboutUs/AboutUs";
import News from "../Screens/News/News";
import FAQPage from "../Screens/FAQ/FAQPage";
import VideosPage from "../Screens/Videos/VideosPage";
import Layout from "../Layout/Layout";
class InnerRoutes extends React.Component {
  render() {
    const {
      match: { url },
    } = this.props;
    return (
      <Layout {...this.props}>
        <Switch>
          <Route exact path={`/`} component={Home} />
          <Route path="/cast-and-crew" component={AboutUs} />
          <Route path="/news" component={News} />
          <Route path="/faq" component={FAQPage} />
          <Route path="/videos" component={VideosPage} />
        </Switch>
      </Layout>
    );
  }
}

export default InnerRoutes;
