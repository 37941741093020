import React from "react";
import { Row, Col, Collapse } from "antd";
import "./FAQ.scss";
import FAQ from "./FAQ";

export default function FAQPage() {
  return (
    <React.Fragment>
      <section className="spacing" />
      <FAQ />
    </React.Fragment>
  );
}
