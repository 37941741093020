import React from "react";
import castCrew from "../../assets/images/crew.png";

export default function AboutUs() {
  return (
    <React.Fragment>
      <section className="spacing" />
      <section className="video-gallery spacing">
        <div className="container text-center">
          <h1 className="d-block w-100 text-capitalize text-white mt-4 mb-4">
            Cast & Crew
          </h1>
          <img src={castCrew} alt="Cast and Crew" className="w-100" />
        </div>
      </section>
    </React.Fragment>
  );
}
