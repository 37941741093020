import React from "react";
import { withRouter } from "react-router";
import Slider from "react-slick";
import "./HomeSlider.scss";
import sider1 from "../../../assets/images/main-slider/1.jpg";
import sider2 from "../../../assets/images/main-slider/2.jpg";
import sider3 from "../../../assets/images/main-slider/3.jpg";
import sider4 from "../../../assets/images/main-slider/4.jpg";
import sider5 from "../../../assets/images/main-slider/5.jpg";

const sliderCarousel = [
  {
    id: 1,
    title: "Naadu - A M.Saravanan Film",
    image: [sider1],
    className: "zoom-in-effect",
  },
  {
    id: 2,
    title: "Naadu - A M.Saravanan Film",
    image: [sider2],
    className: "zoom-in-effect",
  },
  {
    id: 3,
    title: "Naadu - A M.Saravanan Film",
    image: [sider3],
    className: "zoom-in-effect",
  },
  {
    id: 4,
    title: "Naadu - A M.Saravanan Film",
    image: [sider4],
    className: "zoom-in-effect",
  },
  {
    id: 5,
    title: "Naadu - A M.Saravanan Film",
    image: [sider5],
    className: "zoom-in-effect",
  },
];

const Mainslidersettings = {
  draggable: true,
  autoplay: true,
  autoplaySpeed: 7000,
  arrows: false,
  dots: false,
  fade: true,
  speed: 7000,
  infinite: true,
  cssEase: "ease-in-out",
  touchThreshold: 100,
  pauseOnHover: false,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        arrows: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
      },
    },
  ],
};

class HomeSlider extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* <!--Main Slider section starts--> */}
        <div className="archmedia-slider">
          <div className="slider-content ">
            {/* <!--Slider Item starts--> */}
            <Slider {...Mainslidersettings}>
              {sliderCarousel.map((list, index) => (
                <div key={list.id} className="item">
                  <img
                    src={`${list.image}`}
                    className={`${list.className}`}
                    alt={list.title}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
        {/* <!--Main Slider section ends--> */}
      </React.Fragment>
    );
  }
}
export default withRouter(HomeSlider);
