import React from "react";
import { Row, Col, Image } from "antd";
import Slider from "react-slick";
import "./Home.scss";
import crew from "../../assets/images/cast-crew.png";
import castCrew from "../../assets/images/crew.png";
import titleImage from "../../assets/images/title.png";

// movie posters
import poster1 from "../../assets/images/naadu/posters/naadu-tamil-movie-first-look.JPG";
import poster2 from "../../assets/images/naadu/posters/naadu-tamil-movie-firstlook-invite-poster.jpeg";
import poster3 from "../../assets/images/naadu/posters/naadu-poster.jpg";
import poster4 from "../../assets/images/naadu/posters/naadu-poster-2.JPG";
import poster5 from "../../assets/images/naadu/posters/naadu-poster-3.jpg";
import poster6 from "../../assets/images/naadu/posters/naadu-poster-4.jpg";
import poster7 from "../../assets/images/naadu/posters/naadu-tamil-movie-pongal-wishes.jpg";
import poster8 from "../../assets/images/naadu/posters/Jan-26th-2.jpg";
import poster9 from "../../assets/images/naadu/posters/Ugadi.jpg";
import Videos from "../Videos/Videos";
import FAQ from "../FAQ/FAQ";
import HomeSlider from "./HomeSlider/HomeSlider";

const posterSlidersettings = {
  arrows: true,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 2,
  autoplay: true,
  autoplaySpeed: 5000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ],
};

const naaduPosters = [
  {
    id: 1,
    name: "Naadu tamil movie first look release by Makkal Selvan vijay sethupathi",
    image: [poster1],
  },
  {
    id: 2,
    name: "Naadu tamil movie first look Press Release invite poster",
    image: [poster2],
  },
  {
    id: 3,
    name: "Naadu tamil movie first look Press Release invite poster",
    image: [poster3],
  },
  {
    id: 4,
    name: "Naadu tamil movie first look Press Release invite poster",
    image: [poster4],
  },
  {
    id: 5,
    name: "Naadu tamil movie first look Press Release invite poster",
    image: [poster5],
  },
  {
    id: 6,
    name: "Naadu tamil movie first look Press Release invite poster",
    image: [poster6],
  },
  {
    id: 7,
    name: "Naadu tamil movie first look Press Release invite poster",
    image: [poster7],
  },
  {
    id: 8,
    name: "Naadu tamil movie first look Press Release invite poster",
    image: [poster8],
  },
  {
    id: 9,
    name: "Naadu tamil movie first look Press Release invite poster",
    image: [poster9],
  },
];
const movieDetails = [
  {
    id: 1,
    title: "DIRECTOR",
    details: "M Saravanan",
  },
  {
    id: 2,
    title: "PRODUCED BY",
    details: "Sri Arch Media",
  },
  {
    id: 3,
    title: "WRITTEN BY",
    details: "M Saravanan",
  },
  {
    id: 4,
    title: "STARRING",
    details: "Tharshan Thiyagarajah & Mahima Nambiar",
  },
  {
    id: 5,
    title: "MUSIC BY",
    details: "C Sathya",
  },
  {
    id: 6,
    title: "CINEMATOGRAPHY",
    details: "KA Sakthivel",
  },
  {
    id: 7,
    title: "EDITED BY",
    details: "PK",
  },
  {
    id: 8,
    title: "Genres",
    details: "Drama",
  },
];
export default function Home() {
  return (
    <React.Fragment>
      <section className="movie-banner-image">
        <HomeSlider />

        <div className="title-desc">
          <div className="container">
            <div className="flex-design">
              <div className="content">
                <h1 className="text-red text-bold">Naadu</h1>
                <p className="text-white">
                  Naadu is an upcoming Indian Tamil-language film directed by M.
                  Saravanan and will feature Tharshan Thiyagarajah and Mahima
                  Nambiar as lead characters, The feature film is produced by
                  Chakra and Raj and the music composed by C. Sathya.
                </p>
              </div>
              <div className="cast-crew text-center">
                <h6 className="text-white mb-2">Cast & Crew</h6>
                <img src={crew} alt="cast and crew" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-naadu spacing">
        <div className="container">
          <Row
            gutter={[
              { xs: 0, sm: 16, md: 24, lg: 32 },
              { xs: 0, sm: 16, md: 24, lg: 20 },
            ]}
            align="middle"
            className="pb-3 "
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <img src={titleImage} alt="title" className="w-100" />
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <div className="text-light-white">
                <h3 className="mb-3 text-red text-capitalize">About Naadu</h3>
                <p>
                  Naadu is an upcoming Indian Tamil-language film directed by M.
                  Saravanan and will feature Tharshan Thiyagarajah and Mahima
                  Nambiar as lead characters, The feature film is produced by
                  Chakra and Raj. The film starring Tharshan Thiyagarajah in
                  leading role. The film's music composed by C.Sathya, while
                  cinematography and editing is handled by Shakthi and PK
                  respectively.
                </p>
                <p>
                  "Naadu" is scheduled to be released in theaters worldwide on
                  2023.
                </p>
              </div>
              <div className="details mt-3 p-4 border-purple">
                <ul className="text-light-white">
                  {movieDetails.map((data, index) => (
                    <li key={data.id}>
                      <span>{data.title} :</span>
                      {data.details}
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </section>

      <section className="video-gallery spacing">
        <div className="container">
          <h3 className="mb-3 text-center text-red text-capitalize">
            Cast & Crew
          </h3>
          <Row
            gutter={[
              { xs: 0, sm: 16, md: 24, lg: 32 },
              { xs: 0, sm: 16, md: 24, lg: 20 },
            ]}
            align="center"
            className="pb-3 "
          >
            <img src={castCrew} alt="Cast and Crew" className="w-100" />
          </Row>
        </div>
      </section>

      <section className="poster-image spacing">
        <div className="container">
          <h3 className="mb-3 text-center text-red text-capitalize">
            Naadu Posters
          </h3>
          {/* <Image.PreviewGroup
              preview={{
                onChange: (current, prev) =>
                  console.log(`current index: ${current}, prev index: ${prev}`),
              }}
            > */}
          {/* <Row gutter={[30, 20]} className="pb-3 "> */}
          <Slider {...posterSlidersettings}>
            {naaduPosters.map((images, index) => (
              <div key={index} className="list">
                <Image
                  src={images.image}
                  title={`${images.name}`}
                  alt={`${images.name}`}
                />
              </div>
            ))}
          </Slider>
          {/* </Row> */}
          {/* </Image.PreviewGroup> */}
        </div>
      </section>

      <Videos />

      <FAQ />
    </React.Fragment>
  );
}
