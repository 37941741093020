import React from "react";
import { Row, Col } from "antd";

// video thumbnail
import thumbnail1 from "../../assets/images/naadu/video-thumb/naadu-tamil-film-thumbnail-1.jpg";
import thumbnail2 from "../../assets/images/naadu/video-thumb/naadu-tamil-film-thumbnail-2.jpg";
import thumbnail3 from "../../assets/images/naadu/video-thumb/naadu-tamil-film-thumbnail-3.jpg";
import thumbnail4 from "../../assets/images/naadu/video-thumb/naadu-tamil-film-thumbnail-4.jpg";
import thumbnail5 from "../../assets/images/naadu/video-thumb/naadu-tamil-film-thumbnail-5.jpg";
import thumbnail6 from "../../assets/images/naadu/video-thumb/naadu-tamil-film-thumbnail-6.jpg";

const youtubeVideo = [
  {
    id: 1,
    name: "கதை ரொம்ப புடிச்சிருக்கு | Mahima Nambiar Fun 😂 Speech at #Naadu Press Meet",
    image: [thumbnail1],
    videoUrl: "https://www.youtube.com/watch?v=SHIcP9qvQz4",
  },
  {
    id: 2,
    name: "Singampuli Comedy🤣 Speech at Naadu First Look Release | 👏கைதட்டி சிரித்த Mahima, Tharshan !",
    image: [thumbnail2],
    videoUrl: "https://www.youtube.com/watch?v=7dakJYMsexE",
  },
  {
    id: 3,
    name: "Tharshan Fun Speech @Naadu #நாடு Press Meet | First Look Launch | Sri Arch Media | M.Saravanan Movie",
    image: [thumbnail3],
    videoUrl: "https://www.youtube.com/watch?v=yPmTNug-9QM",
  },
  {
    id: 4,
    name: "Music Director C.Sathya speak about #Naadu | First Look Launch | Sriarchmedia",
    image: [thumbnail4],
    videoUrl: "https://www.youtube.com/watch?v=oHxZhMdnLkU",
  },
  {
    id: 5,
    name: "Director M. Saravanan Speech @ Naadu Press Meet",
    image: [thumbnail5],
    videoUrl: "https://www.youtube.com/watch?v=m1rzvf7B6Gw",
  },
  {
    id: 6,
    name: "#NAADU Production #1 | M.Saravanan Film Press Meet | Sri Arch Media | Tharshan | Mahima Nambiar",
    image: [thumbnail6],
    videoUrl: "https://www.youtube.com/watch?v=jWiYSvUWvjI",
  },
];

export default function Videos() {
  return (
    <React.Fragment>
      <section className="video-gallery spacing">
        <div className="container">
          <Row
            gutter={[
              { xs: 0, sm: 16, md: 24, lg: 32 },
              { xs: 0, sm: 16, md: 24, lg: 20 },
            ]}
            align="center"
            className="pb-3 "
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 18 }}
              md={{ span: 18 }}
              lg={{ span: 18 }}
              className="text-center"
            >
              <div className="title">
                <h3 className="mb-3 text-center text-red text-capitalize">
                  Naadu Official Lyric Video
                </h3>
              </div>
              <iframe
                className="iframe-video"
                src="https://www.youtube.com/embed/2nt_jh5ZkAU"
                title="Maavilai Maavilai Thorananga Lyric Video | Naadu | Tharshan, Mahima Nambiar | C.Sathya| M.Saravanan"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
              <h4 className="text-light-white">
                Here's the Official Lyric Video of "Maavilai Maavilai
                Thorananga" from "Naadu", Sung by Anthony Daasan, Lyrics Written
                by Viveka, Music Composed by C. Sathya. Produced by Sri Arch
                Media Pvt Ltd & Directed by M.Saravanan
              </h4>
            </Col>
          </Row>
        </div>
      </section>
      <section className="video-gallery spacing">
        <div className="container">
          <h3 className="mb-3 text-center text-red text-capitalize">
            Video Gallery
          </h3>
          <Row
            gutter={[
              { xs: 0, sm: 16, md: 24, lg: 32 },
              { xs: 0, sm: 16, md: 24, lg: 20 },
            ]}
            align="center"
            className="pb-3 "
          >
            {youtubeVideo.map((videos, index) => (
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
                key={index}
              >
                <a href={`${videos.videoUrl}`} target="_blank" rel="noreferrer">
                  <div className="card">
                    <img
                      src={videos.image}
                      alt={`${videos.name}`}
                      title={`${videos.name}`}
                    />
                    <h4>{videos.name}</h4>
                  </div>
                </a>
              </Col>
            ))}
          </Row>
        </div>
      </section>
    </React.Fragment>
  );
}
