import React from "react";
import { Row, Col, Collapse } from "antd";
import "./FAQ.scss";

const { Panel } = Collapse;
function callback(key) {
  console.log(key);
}

const collapseItem = [
  {
    key: 1,
    title: "Who are the main producers of the movie Naadu?",
    desc: "Chakra and Raj are the main producers of the movie Naadu.",
  },
  {
    key: 2,
    title: "Who is the director of the film Naadu?",
    desc: "M. Saravanan is the director of the film Naadu.",
  },

  {
    key: 3,
    title: "What genre is Naadu expected to be?",
    desc: "Naadu is expected to be a commercial drama entertainer.",
  },
  {
    key: 4,
    title: "Is Naadu a collaboration between Chakra, Raj, and M. Saravanan?",
    desc: "Yes, Naadu is a collaboration between Chakra, Raj, and M. Saravanan.",
  },
  {
    key: 5,
    title: "Which production banner is associated with Naadu?",
    desc: "Naadu is produced under the banner of Sri Arch Media and Entertainment P(LTD), which is owned by Chakra and Raj.",
  },
  {
    key: 6,
    title:
      "What other film did the crew previously work on, which has a similar genre to Naadu?",
    desc: "The crew previously worked on the film Engeyum Epodhum, which was of the same genre as Naadu.",
  },
  {
    key: 7,
    title: "When did the shooting for Naadu begin?",
    desc: "The shooting for Naadu commenced on September 2, 2022.",
  },
  {
    key: 8,
    title: "Where was the shooting of Naadu primarily conducted?",
    desc: "The shooting of Naadu was primarily conducted in various locations of Kolli Hills.",
  },
  {
    key: 9,
    title: "How long did the entire shooting process of Naadu last?",
    desc: "The entire shooting process of Naadu lasted for approximately 30-50 days.",
  },
  {
    key: 10,
    title: "What resolution was used for filming Naadu?",
    desc: "Naadu was shot in 4K resolution.",
  },
  {
    key: 11,
    title: "Who are the lead actors in Naadu?",
    desc: "The lead actors in Naadu are Tharshan Thiyagarajah and Mahima Nambiar.",
  },
  {
    key: 12,
    title: "Can you name some of the cast members of Naadu?",
    desc: "Some of the cast members of Naadu include Singampuli, R.S. Shivaji, Aruldoss, and Inba Ravikumar.",
  },
  {
    key: 13,
    title: "What is the plot or storyline of Naadu?",
    desc: "Naadu is a gripping tale inspired by a real-life incident set in the enchanting landscapes of Kolli Hills. The film revolves around Tharshan Thiyagarajah, an ordinary resident of Kolli Hills, who finds himself in a challenging situation.",
  },
  {
    key: 14,
    title: "Is there any expected release date for the movie Naadu?",
    desc: "Naadu is scheduled to be released in theaters worldwide on 2023.",
  },
];

export default function FAQ() {
  return (
    <React.Fragment>
      <section className="faq spacing">
        <div className="container">
          <Row
            gutter={[
              { xs: 0, sm: 16, md: 24, lg: 32 },
              { xs: 0, sm: 16, md: 24, lg: 20 },
            ]}
            align="center"
            className="pb-3 "
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 18 }}
              md={{ span: 18 }}
              lg={{ span: 18 }}
            >
              <div className="title">
                <h3 className="mb-3 text-center text-red text-capitalize">
                  Frequently Asked Questions
                </h3>
              </div>

              <Collapse ghost onChange={callback} expandIconPosition="right">
                {collapseItem.map((list) => (
                  <Panel header={list.title} key={list.key}>
                    <p>{list.desc}</p>
                  </Panel>
                ))}
              </Collapse>
            </Col>
          </Row>
        </div>
      </section>
    </React.Fragment>
  );
}
